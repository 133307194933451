<template>
  <b-card title="Tambah Materi ➕">
    <b-form @submit.prevent>
      <b-row>
        <!-- first name -->
        <b-col cols="12">
          <b-form-group
            label="Nama materi"
            label-for="v-nama-materi"
          >
            <b-form-input
              id="v-nama-materi"
              v-model="data.nama_materi"
              placeholder="Nama materi"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Pilih Isi Materi"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="m"
              name="radio-btn-outline"
              buttons
            ></b-form-radio-group>
        </b-form-group>
        </b-col>
        <b-col cols="12" v-if="selected=='video'">
          <b-form-group
            label="Kode unik video"
            label-for="v-link-video"
          >
            <b-form-input
              id="v-link-video"
              v-model="data.link_video"
              placeholder="Kode unik video"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="selected=='pdf'">
          <b-form-group
            label="File PDF"
            label-for="v-file-pdf"
          >
            <b-form-file
              id="v-file-pdf"
              v-model="data.file_pdf"
              placeholder="File PDF"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
                label="penjelasan"
                label-for="v-penjelasan"
            >
            <quill-editor
                id="Penjelasan"
                rows="4"
                v-model="data.penjelasan"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Status"
            label-for="v-status"
          >
            <b-form-select
            v-model="data.status"
            :options="options_status"
          />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="saveData()"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormSelect, BFormRadioGroup, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'

const Block = Quill.import('blots/block')
Block.tagName = 'span'
Quill.register(Block)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    quillEditor,
    BFormSelect,
    BFormRadioGroup,
    BFormFile,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      data: {
        nama_materi: '',
        link_video: '',
        file_pdf: '',
        penjelasan: '',
        status: '',
      },
      selected: 'video',
      options_status: [
        { value: '1', text: 'Aktif' },
        { value: '0', text: 'Tidak Aktif' },
      ],
      options: [
        { text: 'Video', value: 'video' },
        { text: 'PDF', value: 'pdf' },
      ],
    }
  },
  methods: {
    saveData() {
      const data = new FormData()
      data.append('nama_materi', this.data.nama_materi)
      data.append('id_bab', this.$route.params.idBab)
      data.append('link_video', this.data.link_video)
      data.append('file_pdf', this.data.file_pdf)
      data.append('penjelasan', this.data.penjelasan)
      data.append('status', this.data.status)
      this.$http.post(`/bab-materi/${this.$route.params.idBab}/materi`, data).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'materi' })
          }, 500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
